import React, { useState } from 'react';
import Produtos from './Produtos';
import './Login.css'; // Estilos importados do HTML

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = (e) => {
    e.preventDefault();
    if (email === 'atoomic@company.com' && password === 'At00m!c_2o2e') {
      setIsLoggedIn(true);
    } else {
      alert('Credenciais inválidas');
    }
  };

  if (isLoggedIn) {
    return  <Produtos />; // Componente que será exibido após o login bem-sucedido
  }

  return (
    <div className="container">
      <div className="logo">
        <img src="https://plataformaninja.com/images/clientes/atoomic/logo.jpg" alt="Atoomic Logo" />
      </div>
      <form onSubmit={handleLogin}>
        <input
          type="email"
          id="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="password"
          id="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="Entrar">Sign In</button>
      </form>
    </div>
  );
};

export default App;
